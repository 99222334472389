import React from "react"
import { Link, graphql } from "gatsby"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"

import Layout from "../components/layout"

const Supervision = ({ data, location }) => {
  dayjs.extend(advancedFormat)
  const post = data.wordpressWpSupervisions

  return (
    <Layout>
      <h3
        className="font-sans font-light mb-4 text-2xl sm:text-3xl underline"
        dangerouslySetInnerHTML={{ __html: post.title }}
      />
      <div className="border-2 border-purple-500 bg-purple-200 p-3 mr-1 rounded-lg shadow">
        <img
          className="rounded-lg max-w-full sm:max-w-sm h-48 object-cover"
          src={`${post.acf.image.source_url}`}
          alt={`${post.title} poster`}
        />
        <h3 className="font-sans font-light mb-4 text-lg">
          {post.acf.artists}
        </h3>
        <h3 className="font-sans font-light mb-4 text-lg">
          {post.acf.location}
        </h3>
        <h3 className="font-sans font-light mb-4 text-lg">
          {post.acf.start_date &&
            dayjs(post.acf.start_date, "YYYYMMDD").format("Do MMMM, YYYY")}{" "}
          {post.acf.end_date &&
            `- ${dayjs(post.acf.end_date, "YYYYMMDD").format("Do MMMM, YYYY")}`}
        </h3>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <div>
          {post.acf.website_link && (
            <a href={post.acf.website_link} className="underline mr-2">
              Website
            </a>
          )}

          {post.acf.twitter && (
            <a href={post.acf.twitter} className="underline mr-2">
              Twitter
            </a>
          )}

          {post.acf.facebook && (
            <a href={post.acf.facebook} className="underline mr-2">
              Facebook
            </a>
          )}
        </div>

        {post.content && (
          <div
            className="mt-4 overflow-hidden"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        )}
        <div className="mt-4">
          <Link
            className="underline"
            to={
              location.state && location.state.prevPath
                ? location.state.prevPath
                : "/supervision"
            }
          >
            Back
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    wordpressWpSupervisions(slug: { eq: $slug }) {
      title
      content
      acf {
        start_date
        end_date
        location
        current
        website_link
        twitter
        facebook
        image {
          source_url
          localFile {
            absolutePath
          }
        }
      }
    }
  }
`

export default Supervision
